import { AppState, HomePageParisAppState } from '../../../initialStateRegistration';
import { SectionNames, HomePageParisMinorSections } from './homePageParis.initialState';

export const getHomePage = (state: AppState) => (state as HomePageParisAppState).template;

export const getSectionDataByKey = (key: SectionNames) => (state: AppState) => getHomePage(state)[key];

export const getSectionArticlesByKey = (key: SectionNames) => (state: AppState) => getHomePage(state)[key].articles;

export const getSectionTitleByKey = (key: SectionNames) => (state: AppState) => getHomePage(state)[key].sectionTitle;

export const getMoreButtonTextByKey = (key: HomePageParisMinorSections) => (state: AppState) => getHomePage(state)[key].moreButtonText;

export const getMoreButtonSvgByKey = (key: HomePageParisMinorSections) => (state: AppState) => getHomePage(state)[key].moreButtonSvg;

export const getMetadataDescription = (state: AppState) => getHomePage(state).metadataDescription;

export const getMetadataTitle = (state: AppState) => getHomePage(state).metadataTitle;

export const getMetadataImage = (state: AppState) => getHomePage(state).metadataImage;

export const getHreflangData = (state: AppState) => getHomePage(state).hreflangData;
