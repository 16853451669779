import React from 'react';
import { useSelector } from 'react-redux';
import {
  Ad,
  AppContent,
  baseUnit,
  ErrorBoundary,
  HeadlinesArticleList,
  MEDIA_BREAKPOINTS,
  OneThreeArticleList,
  ScrollableArticleList,
  SecondarySectionTitle,
  Section,
  SectionHeader,
  SectionWithTitle,
} from 'mm-ui-components';
import { Store } from 'redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Theme } from '../../components/connectedComponents/Theme';
import { MetaTags } from '../../components/connectedComponents/MetaTags';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { SECTION_NAMES, SLOT_NAMES } from '../templates.const';
import { Page } from '../Page';
import {
  getMetadataDescription,
  getMetadataTitle,
  getMoreButtonSvgByKey,
  getMoreButtonTextByKey,
  getSectionArticlesByKey,
  getSectionTitleByKey,
  getMetadataImage,
  getHreflangData,
} from '../../store/template/homePage/homePageParis/homePageParis.selectors';
import { getAdSlotId, getAdSlotOnSuccess } from '../../store/config/config.selectors';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';

interface HomePageParisProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
}

const getArticlesInTemplate = (state: AppState) => {
  const topSectionArticles = getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)(state) || [];
  const cardsSectionArticles = getSectionArticlesByKey(SECTION_NAMES.CARDS_SECTION)(state) || [];
  const contentSection1Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_1)(state) || [];
  const contentSection2Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_2)(state) || [];
  const contentSection3Articles = getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_3)(state) || [];
  return [
    ...topSectionArticles,
    ...cardsSectionArticles,
    ...contentSection1Articles,
    ...contentSection2Articles,
    ...contentSection3Articles,
  ];
};

export const HomePageParisComponent: React.FunctionComponent<HomePageParisProps> = ({ store, errorProvider }) => {
  useMeasureHydrateTime();
  return (
    <Page store={store} errorProvider={errorProvider}>
      <ErrorBoundary>
        <HomePageParisContentConnected />
      </ErrorBoundary>
    </Page>
  );
};

const createStyle = () => StyleSheet.create({
  sectionsWrapper: {
    [MEDIA_BREAKPOINTS.large]: {
      gridRowGap: `${baseUnit * 3}px`,
    },
    [MEDIA_BREAKPOINTS.medium]: {
      gridRowGap: `${baseUnit * 2}px`,
    },
    [MEDIA_BREAKPOINTS.small]: {
      gridRowGap: `${baseUnit * 2}px`,
    },
    display: 'grid',
    gridTemplateColumns: '1fr',
    marginTop: `${baseUnit * 0.25}px`,
    marginBottom: `${baseUnit * 0.5}px`,
    justifyItems: 'center',
  },
});

export const HomePageParisContentConnected: React.FunctionComponent = () => {
  const topSectionDisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.TOP_SECTION));
  const topSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION));
  const cardsSectionDisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CARDS_SECTION));
  const cardsSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CARDS_SECTION));
  const cardsSectionMoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CARDS_SECTION));
  const cardsSectionMoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CARDS_SECTION));
  const contentSection1DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection1Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection1MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection1MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_1));
  const contentSection2DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection2Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection2MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection2MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_2));
  const contentSection3DisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const contentSection3Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const contentSection3MoreButtonSvg = useSelector(getMoreButtonSvgByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const contentSection3MoreButtonText = useSelector(getMoreButtonTextByKey(SECTION_NAMES.CONTENT_SECTION_3));
  const articles = useSelector(getArticlesInTemplate);
  const topAdId = useSelector(getAdSlotId(SLOT_NAMES.TOP_AD));
  const oneOnOneId = useSelector(getAdSlotId(SLOT_NAMES.ONE_ON_ONE));
  const topAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.TOP_AD));
  const oneOnOneOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.ONE_ON_ONE));
  const metaTitle = useSelector(getMetadataTitle);
  const metaDescription = useSelector(getMetadataDescription);
  const pageImage = useSelector(getMetadataImage);
  const hreflangData = useSelector(getHreflangData) || [];
  const style = createStyle();
  return (
    <React.Fragment>
      <MetaTags articles={articles} title={metaTitle} description={metaDescription} pageImage={pageImage} alternateUrls={hreflangData} />
      <HeadScripts />
      <Theme>
        <AppContent>
          <NavigationLayout mainWrapperConfigThemeKey="mainContent">
            <Ad id={topAdId} onSuccess={topAdOnSuccess} setMinHeight />
            <div className={css(style.sectionsWrapper)}>

              <Section>
                <HeadlinesArticleList displayName={topSectionDisplayName} cardsProps={topSectionArticles} />
              </Section>

              <SectionWithTitle>
                <SectionHeader moreButtonSvg={cardsSectionMoreButtonSvg} moreButtonText={cardsSectionMoreButtonText}>
                  <SecondarySectionTitle>
                    {cardsSectionDisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <ScrollableArticleList
                  cardsProps={cardsSectionArticles}
                />
              </SectionWithTitle>

              <SectionWithTitle>
                <SectionHeader
                  moreButtonSvg={contentSection1MoreButtonSvg}
                  moreButtonText={contentSection1MoreButtonText}
                >
                  <SecondarySectionTitle>
                    {contentSection1DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <OneThreeArticleList cardsProps={contentSection1Articles} />
              </SectionWithTitle>

              <SectionWithTitle>
                <SectionHeader
                  moreButtonSvg={contentSection2MoreButtonSvg}
                  moreButtonText={contentSection2MoreButtonText}
                >
                  <SecondarySectionTitle>
                    {contentSection2DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <OneThreeArticleList cardsProps={contentSection2Articles} />
              </SectionWithTitle>

              <SectionWithTitle>
                <SectionHeader
                  moreButtonSvg={contentSection3MoreButtonSvg}
                  moreButtonText={contentSection3MoreButtonText}
                >
                  <SecondarySectionTitle>
                    {contentSection3DisplayName}
                  </SecondarySectionTitle>
                </SectionHeader>
                <OneThreeArticleList cardsProps={contentSection3Articles} />
              </SectionWithTitle>

            </div>
            <Ad id={oneOnOneId} onSuccess={oneOnOneOnSuccess} />
          </NavigationLayout>
        </AppContent>
      </Theme>
    </React.Fragment>
  );
};
